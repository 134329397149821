import * as React from 'react'

interface CLevelCardProps {
  item: any
}

const CLevelCard = ({ item }: CLevelCardProps) => {
  return (
    <div className={`card width-30 mx-2 first:ml-4 last:mr-4 relative`}>
      <img
        alt=""
        src={item.image}
        className="w-full object-fit-cover rounded-t-2xl"
      />

      <div className="p-4">
        <h4 className="text-xl md:text-3xl font-semibold text-left">
          {item.name}
        </h4>

        <h5 className="text-lg md:text-2xl font-semibold text-left">
          {item.position}
        </h5>

        <ul className="list-disc ml-8 mt-4 mb-12">
          {item.infos.map((listItem: any) => (
            <li className="text-left">{listItem}</li>
          ))}
        </ul>

        <a
          href={item.Linked}
          target="_blank"
          className="absolute left-4 bottom-5"
        >
          <div className="text-gray-400 flex items-center font-semibold">
            Visit Linkedin{' '}
            <svg
              className="ml-2"
              width="10"
              height="16"
              viewBox="0 0 10 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L7.08579 8L0.792893 14.2929C0.402369 14.6834 0.402369 15.3166 0.792893 15.7071C1.18342 16.0976 1.81658 16.0976 2.20711 15.7071L9.20711 8.70711C9.59763 8.31658 9.59763 7.68342 9.20711 7.29289L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893Z"
                fill="#A6A9AF"
              />
            </svg>
          </div>
        </a>
      </div>
    </div>
  )
}

export default CLevelCard
