import { useEffect } from 'react'
import GA from 'react-ga4'
import { useLocation } from '@reach/router'

const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID

const useGoogleAnalytics = () => {
  const location = useLocation()
  const path = location.pathname

  useEffect(() => {
    if (GA_MEASUREMENT_ID && GA_MEASUREMENT_ID.length > 1) {
      GA.initialize([
        {
          trackingId: GA_MEASUREMENT_ID,
        },
      ])

      GA.send({ hitType: 'pageview', page: path })
    }
  }, [path])
}

export { useGoogleAnalytics }
