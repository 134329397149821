import * as React from 'react'
import { useScrollDirection } from 'react-use-scroll-direction'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Lottie from 'react-lottie'
import CountUp, { useCountUp } from 'react-countup'
import type { HeadFC } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Header from '../common/Header'
import Footer from '../common/Footer'
import Social from '../common/Social'
import CLevelCard from '../common/CLevelCard'

import * as animationBanner from '../images/lotties/banner.json'
import * as animationHand from '../images/lotties/hand.json'
import * as animationCup from '../images/lotties/cup.json'
import * as animationDropMoney from '../images/lotties/drop-money.json'
import * as animationBlinkEye from '../images/lotties/blink-eye.json'

import LinhHan from '../images/imgs/Linh_Han.png'
import LinhHan2 from '../images/imgs/Linh_Han2.png'
import DzungNguyen from '../images/imgs/Dzung_Nguyen.png'
import HieuBui from '../images/imgs/Hieu_Bui.png'
import Phuonglai from '../images/imgs/Phuong_Lai.png'
import DuongDo from '../images/imgs/Duong_Do.png'
import TraHoang from '../images/imgs/Tra_Hoang.png'
import CatieHangLe from '../images/imgs/Catie_Hang_Le.png'

import thumbnail from '../images/hectagon_thumbnail.png'
import RaiseFundImg from '../images/imgs/raisefund_bg.png'
import line from '../images/imgs/Line.png'
import RoadmapMobile from '../images/imgs/roadmap-mobile.png'
import pancake from '../images/icons/pancake.png'
import discount from '../images/icons/discount.png'
import arow from '../images/icons/arow.svg'
import arrowBlack from '../images/icons/arrow_black.svg'
import launchZone from '../images/logo/launch_zone.png'
import vsv from '../images/logo/vsv.png'
import mistletoe from '../images/logo/mistletoe.png'
import hub from '../images/logo/hub.png'
import tomoChain from '../images/logo/tomo_chain.png'
import yahoo from '../images/logo/yahoo.png'
import aol from '../images/logo/aol-logo.png'
import marketWatch from '../images/logo/market-watch.png'
import nasdaq from '../images/logo/NASDAQ.png'
import { animationData } from '../helpers'
import { useGoogleAnalytics } from '../helpers/useGoogleAnalytics'
import { ADVISORS } from '../constants'

const STATIC = [
  { value: 40, sub: 'K+', title: 'Community Members' },
  { value: 61, sub: '', title: 'Strategic Partnerships' },
  { value: 210, sub: '+', title: 'Web3 Projects in review' },
  { value: 389, sub: '', title: 'Media mentions' },
]

const STATIC_1 = [
  { value: 61, sub: '', title: 'Strategic Partnerships' },
  { value: 389, sub: '', title: 'Media mentions' },
]

const STATIC_2 = [
  { value: 40, sub: 'K+', title: 'Community Members' },
  { value: 210, sub: '+', title: 'Web3 Projects in review' },
]

const PARTNER = [launchZone, vsv, mistletoe, hub, tomoChain]

const INVEST = [
  {
    img: animationHand,
    title: 'Easy-to-Use System',
    description:
      'Simply buy & hold $HECTA token to contribute to Hectagon’s treasury and let protocol buy private/seed tokens from promising projects.',
    contentItem: [
      'No minimum buy-in amount.',
      'Buy $HECTA token using any popular tokens.',
      'An easy-to-use user experience.',
    ],
  },
  {
    img: animationCup,
    title: 'Winning Investment Strategy',
    description:
      'Your contribution is run by professionals, investing in private/seed rounds with a strong deal pipeline, providing long term value-added to stakeholders.',
    contentItem: [
      'Provide VCs a good co-invest partner who hold project tokens long-term.',
      `Incentivize professionals to join Protocol's Investment Committee to get high ROI.`,
    ],
  },
  {
    img: animationDropMoney,
    title: 'Community Rewards System',
    description:
      'Hectagon empowers the investor community and project portfolio to create rewards mechanisms. Retail investors can work-to- earn additional income through:',
    contentItem: [
      'Invest & stake HECTA token',
      'Provide value-added activities to the Protocol and Projects portfolio (Marketing, community growth, design etc.)',
    ],
  },
]

const LIST_ROADMAP = [
  {
    month: 'Jan',
    year: '2022',
    active: true,
    listContent: ['Team Onboarding', 'Docs and Whitepaper', 'Strategic Round'],
  },
  {
    month: 'May',
    year: '2022',
    active: true,
    listContent: ['Private Round ', 'NFTs and Marketplace', 'Rewards System'],
  },
  {
    month: 'Aug',
    year: '2022',
    active: true,
    listContent: [
      'Public Sales on Aug 8',
      'All systems on Mainnet',
      'Live on PancakeSwap',
    ],
  },
  {
    month: 'Q4',
    year: '2022',
    active: false,
    listContent: [
      'DAO Voting for Investment',
      'Mint Redeemable NFTs',
      'Portfolio Management',
    ],
  },
  {
    month: 'H1',
    year: '2023',
    active: false,
    listContent: [
      'Deals referral for community',
      'Audit Investment performance',
      'Investment Specialist feature',
    ],
  },
]

const NEWS_1 = [
  {
    icon: yahoo,
    description:
      'Unlike other DAOs that raised millions without a plan on what to do next, Hectagon has a clear mandate of accessing the best seed-stage Web3 deals',
    link: 'https://news.yahoo.com/hectagon-launch-tge-august-8-085011911.html',
  },
  {
    icon: nasdaq,
    description:
      'Hectagon is a novel platform that lets regular users get the windfall from investing into crypto startups at an early stage',
    link:
      'https://www.nasdaq.com/press-release/hectagon-to-launch-tge-on-august-8-aiming-to-bootstrap-decentralized-vc-dao-2022-08',
  },
  {
    icon: marketWatch,
    description:
      'With Hectagon, holding HECTA gives users a way to indirectly hold seed-stage tokens',
    link:
      'https://www.marketwatch.com/press-release/hectagon-to-launch-tge-on-august-8-aiming-to-bootstrap-decentralized-vc-dao-2022-08-07/',
  },
  {
    icon: aol,
    description:
      'Hectagon’s mission is restoring the fair community-centric ideals of the early crypto years, which later were suppressed by a Web2-like Venture Fund model.',
    link:
      'https://www.aol.com/news/hectagon-launch-tge-august-8-085011911.html',
  },
]

const C_LEVEL_1 = [
  {
    image: LinhHan2,
    name: 'Linh Han',
    position: 'CEO and Founder',
    infos: [
      'Forbes Asia 30 Under 30 (2020)',
      'Founder of VSV Capital and Hub Global',
      '10 years in VC Investment, Finance and Startups Development',
      'Invested in 100+ early-stage Web2 and Web3 startups',
    ],
    Linked: 'https://www.linkedin.com/in/linhhan/',
  },
  {
    image: DzungNguyen,
    name: 'Dzung Nguyen',
    position: 'CFO',
    infos: [
      'Former CFO of HBRE Wind Power and Founder of XECA.vn',
      '8 years in Startups, Finance and Operations Management',
      'Tokenomics Advisors for 30+ Web3 projects',
    ],
    Linked: 'https://www.linkedin.com/in/nguyen-quang-dung-695ba33a/',
  },
  {
    image: HieuBui,
    name: 'Hieu Bui',
    position: 'CPO',
    infos: [
      'Founder of Edumall (Topica) and TerraBook',
      '12 years in Startups, Software Engineering and Product Design',
      'Founded 1M+ users startup. Grew 128M+ views Youtube channel',
    ],
    Linked: 'https://www.linkedin.com/in/trunghieubui88/',
  },
]

const C_LEVEL_2 = [
  {
    image: Phuonglai,
    name: 'Phuong Lai',
    position: 'CTO',
    infos: [
      'Former Head of Engineering of Tomochain',
      '15 years in Startup, IT and Software Engineering',
      'Serial-CTO of Gapo Social Network, Amela Technology, HBlab Outsourcing ',
    ],
    Linked: 'https://www.linkedin.com/in/laiphuong/',
  },
  {
    image: DuongDo,
    name: 'Duong Do',
    position: 'COO',
    infos: [
      'Former Head of Compliance of VCC Exchange',
      '8 years in Compliance Banking and Operations Management',
      'Expert in eKYC and AML',
    ],
    Linked: 'https://www.linkedin.com/in/duong-do-108924150/',
  },
  {
    image: CatieHangLe,
    name: 'Catie Hang Le',
    position: 'Advisory CMO',
    infos: [
      'Marketing Lead of serial Silicon Valley and Vietnam startups (Misfit, Arevo, Up…)',
      '10 years in Startups, Marketing and Branding',
      'Expert in Crowdfunding and Community Development',
    ],
    Linked: 'https://www.linkedin.com/in/thuhangle90/',
  },
]

const C_LEVEL_3 = [
  {
    image: TraHoang,
    name: 'Felicia Tra Hoang',
    position: 'CGO',
    infos: [
      'Managing Partner of VSV Capital - Accelerated 200 global startups',
      '8 years in Startups, Partnership and BD. Managed USD 80M+ businesses',
      'Co-founded 2 companies in UK and Vietnam',
    ],
    Linked: 'https://www.linkedin.com/in/traht129/',
  },
]

const IndexPage = () => {
  useGoogleAnalytics()
  const [featureIndex, setFeatureIndex] = useState(0)
  const [data, setData] = useState<any>()
  const [isUp, setIsUp] = useState(false)
  const { isScrollingUp, isScrollingDown } = useScrollDirection()

  useEffect(() => {
    fetch(`https://pricefeed.hectagon.finance/cheapest`)
      .then((response) => response.json())
      .then((resultData) => {
        setData(resultData)
      })
  }, [])

  useEffect(() => {
    isScrollingUp && setIsUp(true)
    isScrollingDown && setIsUp(false)
  }, [isScrollingUp, isScrollingDown])

  const isBrowser = typeof window !== 'undefined'
  const isMobile = isBrowser && window.innerWidth < 768
  const isTablet = isBrowser && window.innerWidth <= 768
  const C_LEVEL = [...C_LEVEL_1, ...C_LEVEL_2, ...C_LEVEL_3]

  const handleScrollToTop = () => {
    const el = document.getElementById('#')
    el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <>
      <Helmet>
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://hectagon.finance/" />
        <meta
          property="og:title"
          content="Hectagon | The world’s first DAO-governed Web3 Venture Capital funding platform for everyone"
        />
        <meta
          property="og:description"
          content="Co-invest in Web3 seed investments with experienced Venture Capital network. Invest wisely with less effort!"
        />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:card" content="website" />
        <meta property="twitter:url" content="https://hectagon.finance" />
        <meta
          property="twitter:title"
          content="Hectagon | The world’s first DAO-governed Web3 Venture Capital funding platform for everyone"
        />
        <meta
          property="twitter:description"
          content="Co-invest in Web3 seed investments with experienced Venture Capital network. Invest wisely with less effort!"
        />
        <meta property="og:image" content={thumbnail} />
      </Helmet>

      <section className="background_linear" id="#">
        <Header />

        {isUp && (
          <button
            className="scoll-to-top-button"
            onClick={() => handleScrollToTop()}
          >
            <div className="scoll-to-top" />
          </button>
        )}

        <div className="w-11/12 md:w-10/12 m-auto">
          <div className="md:flex items-center">
            <div className="md:mr-40">
              <h1 className="text-center md:text-left text-white text-lg md:text-3xl font-semibold mt-12">
                Making early-investment in Web3 easily
              </h1>

              <p className="text-base text-white text-center md:text-left">
                HectagonDAO is a collective of experienced investors, builders,
                and contributors. We want to transform investing by making early
                investment accessible and stress-free for everyone.
              </p>

              <a href="https://app.hectagon.finance/invest/buy" target="_blank">
                <button className="h-12 px-20 order-1 rounded-lg bg-white text-primary text-base font-semibold mt-4 whitespace-nowrap hidden md:block">
                  Start investing now
                </button>
              </a>
            </div>

            <div
              className={`lg:w-4/5 ${
                isMobile ? '' : 'min-height-80'
              } flex items-center`}
            >
              <Lottie options={animationData(animationBanner)} />
            </div>

            <div className="flex justify-center w-full md:hidden">
              <a href="https://app.hectagon.finance/invest/buy" target="_blank">
                <button className="h-12 px-20 order-1 rounded-lg bg-white text-primary text-base font-semibold whitespace-nowrap">
                  Start investing now
                </button>
              </a>
            </div>
          </div>

          {isMobile ? (
            <div className="flex justify-between mt-12 pb-12 px-4">
              <div className="w-1/3">
                {STATIC_1.map((item) => (
                  <div className="text-white mb-6">
                    <p className="text-5xl md:text-6xl font-bold">
                      <CountUp
                        start={0}
                        end={item.value}
                        duration={3}
                        suffix={item.sub}
                        delay={1000000}
                        enableScrollSpy
                        scrollSpyOnce
                      />
                    </p>
                    <p className="md:text-xl md:font-medium">{item.title}</p>
                  </div>
                ))}
              </div>

              <div className="w-2/5">
                {STATIC_2.map((item) => (
                  <div className="text-white mb-6">
                    <p className="text-5xl md:text-6xl font-bold">
                      <CountUp
                        start={0}
                        end={item.value}
                        duration={3}
                        suffix={item.sub}
                        delay={1000000}
                        enableScrollSpy
                        scrollSpyOnce
                      />
                    </p>
                    <p className="md:text-xl md:font-medium">{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-between mt-20 pb-20">
              {STATIC.map((item) => (
                <div className="text-white">
                  <p className="text-5xl md:text-6xl font-bold">
                    <CountUp
                      start={0}
                      end={item.value}
                      duration={3}
                      suffix={item.sub}
                      delay={1000000}
                      enableScrollSpy
                      scrollSpyOnce
                    />
                  </p>
                  <p className="md:text-xl md:font-medium">{item.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <main className="w-11/12 md:w-10/12 m-auto">
        <section className="mt-14 md:mt-28">
          <h3 className="text-center font-medium md:font-semibold text-xl md:text-2xl">
            Supported by familiar crypto faces
          </h3>

          <div className="flex grid gap-4 grid-cols-1 md:grid-cols-5 mt-7">
            {PARTNER.map((item) => (
              <div className="flex justify-center items-center py-2">
                <img src={item} alt="" />
              </div>
            ))}
          </div>
        </section>

        <section className="mt-20 md:mt-28" id="Key-features">
          <h1 className="text-center font-semibold text-2xl md:text-5xl">
            All possible through <span className="text-linear">blockchain</span>
          </h1>

          <div className="hidden md:flex grid gap-20 grid-cols-3 mt-7">
            {INVEST.map((item) => (
              <div>
                <div className="flex justify-center">
                  <Lottie
                    height={318}
                    width={318}
                    options={animationData(item.img)}
                  />
                </div>

                <h2 className="text-2xl font-semibold mt-8 md:mt-14">
                  {item.title}
                </h2>

                <p className="mt-5">{item.description}</p>

                <ul className="list-disc ml-8 mt-4">
                  {item.contentItem.map((listItem) => (
                    <li>{listItem}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="md:hidden mt-7">
            <div className="flex justify-center">
              <Lottie
                height={318}
                width={318}
                options={animationData(INVEST[featureIndex].img)}
              />
            </div>

            <h2 className="text-center md:text-left text-xl md:text-2xl font-semibold mt-8">
              {INVEST[featureIndex].title}
            </h2>
            <p className="mt-5">{INVEST[featureIndex].description}</p>

            <ul className="list-disc ml-8 mt-4">
              {INVEST[featureIndex].contentItem.map((listItem) => (
                <li>{listItem}</li>
              ))}
            </ul>

            <div className="flex justify-center items-center mt-6">
              <button
                className={
                  featureIndex == 0 ? 'page-button-select' : 'page-button'
                }
                onClick={() => setFeatureIndex(0)}
              >
                1
              </button>
              <button
                className={
                  featureIndex == 1 ? 'page-button-select' : 'page-button'
                }
                onClick={() => setFeatureIndex(1)}
              >
                2
              </button>
              <button
                className={
                  featureIndex == 2 ? 'page-button-select' : 'page-button'
                }
                onClick={() => setFeatureIndex(2)}
              >
                3
              </button>
            </div>
          </div>
        </section>
      </main>

      <section className="mt-48 md:mt-72 text-white">
        <div className="background_linear_light h-44">
          <div className="w-11/12 md:w-10/12 m-auto">
            <div className="background_linear box-shadow-gradient px-7 md:px-12 py-6 md:py-9 md:flex items-center justify-between rounded-2xl -translate-y-1/2">
              <div>
                <h3 className="font-semibold text-center md:text-left text-lg md:text-3xl mb-7 md:mr-36">
                  Start investing now with our 40K members, and growing
                  community!
                </h3>

                <div className="md:h-6/12">
                  <Social />
                </div>
              </div>

              <a href="https://app.hectagon.finance/invest/buy" target="_blank">
                <button className="w-full h-12 md:w-60 order-1 rounded-lg bg-primary text-white text-base font-semibold mt-4">
                  <div className="flex justify-center items-center">
                    Go to the app <img src={arow} className="w-6" alt="" />
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>

        <div
          className="w-11/12 md:w-10/12 m-auto mt-10 md:mt-0 z-50"
          id="press"
        >
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div className="md:h-3/5 mb-10">
              <h2 className="font-semibold text-2xl md:hidden text-black">
                In the <span className="text-linear">news</span>
              </h2>
              <Lottie options={animationData(animationBlinkEye)} />

              <h2 className="hidden md:block text-center font-semibold text-5xl text-black">
                In the <span className="text-linear">news</span>
              </h2>
            </div>

            <div className="flex flex-col justify-end">
              {NEWS_1.map((news) => (
                <div className="border-b border-black pb-4 pt-9 md:pt-10">
                  <img src={news.icon} alt="" />
                  <p className="text-sm md:text-lg opacity-40 text-black my-4 md:font-semibold">
                    {news.description}
                  </p>

                  <a href={news.link} target="_blank">
                    <div className="text-sm md:text-base text-black flex items-center font-semibold py-4">
                      Read article{' '}
                      <img src={arrowBlack} className="w-4 h-4 ml-2.5" alt="" />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="w-11/12 md:w-10/12 m-auto">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2"></div>
        </div>
      </section>

      <section>
        <div className="w-11/12 md:w-10/12 m-auto bg-slide-advisor relative">
          <h2
            className={`text-center md:text-left sm:ml-10 font-semibold text-2xl md:text-5xl absolute top-24 sm:top-12 md:top-24 left-1/4 sm:left-1/3 md:left-0 ${
              isTablet ? '' : 'top-advisor-38'
            } md:h-0`}
          >
            Our advisors <br />
            <span className="text-linear-title">Behind the wins</span>
          </h2>

          <img
            alt=""
            src={line}
            className="md:w-full absolute top-2/3 sm:top-3/4 md:top-2/3 "
          />

          <Carousel
            showThumbs={!isTablet}
            showStatus={false}
            showArrows={false}
            infiniteLoop
            autoPlay
            swipeable={false}
            showIndicators={isTablet}
            useKeyboardArrows
            transitionTime={1000}
          >
            {ADVISORS.map((item) => (
              <div className="slide-advisor mt-44 sm:mt-36 md:mt-47 md:flex md:items-center">
                <div className="md:w-1/2"></div>
                <img alt="" src={item.image} className="w-3/5" />
                <div className="md:w-1/2">
                  <h2 className="text-center md:text-left text-linear-title text-3xl md:text-5xl font-semibold">
                    {item.name}
                  </h2>
                  <ul className="md:list-disc ml-8 mt-4">
                    {item.infos.map((listItem) => (
                      <li className="text center md:text-left text-sm md:text-base">
                        {listItem}
                      </li>
                    ))}
                  </ul>

                  <a href={item.linkIn} target="_blank">
                    <div className="text-black flex items-center justify-center md:justify-start font-semibold py-4">
                      Profile{' '}
                      <svg
                        className="ml-2"
                        width="10"
                        height="14"
                        viewBox="0 0 10 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.792893 0.292893C0.402369 0.683417 0.402369 1.31658 0.792893 1.70711L7.08579 8L0.792893 14.2929C0.402369 14.6834 0.402369 15.3166 0.792893 15.7071C1.18342 16.0976 1.81658 16.0976 2.20711 15.7071L9.20711 8.70711C9.59763 8.31658 9.59763 7.68342 9.20711 7.29289L2.20711 0.292893C1.81658 -0.0976311 1.18342 -0.0976311 0.792893 0.292893Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </Carousel>

          <div className="hidden md:flex  justify-between mx-6">
            {ADVISORS.map((item) => (
              <div className="px-6 md:px-0">
                <h5 className="text-xl text-center font-semibold">
                  {item.name}
                </h5>

                <p className="w-44 text-center text-sm">{item.position}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="mt-16 md:mt-32 text-white background_linear">
        <div className="w-11/12 md:w-10/12 m-auto py-10 md:py-24">
          <div className="md:flex items-center">
            <div className="md:mr-44">
              <h2 className="font-semibold text-2xl md:text-5xl text-linear-light mb-6 md:mb-0">
                This is just the begining
              </h2>
              <p className="hidden md:block text-3xl font-tinos my-9">
                “Blockchain is open so Web3 Investment should be open! It’s
                natural progression of the decentralized society.”
              </p>
              <h4 className="hidden md:block font-semibold text-lg md:text-2xl text-linear-light">
                Linh Han,
                <br />
                Founder and CEO - Hectagon Finance
              </h4>
            </div>

            <img src={LinhHan} className="w-4/5 md:w-fit" alt="" />

            <div className="flex justify-end -translate-y-1/3">
              <p className="md:hidden text-xl text-black font-serif my-9 bg-primary-light p-2 rounded-lg w-4/5">
                “Blockchain is open so Web3 Investment should be open! It’s
                natural progression of the decentralized society.”
              </p>
            </div>

            <h4 className="md:hidden font-semibold text-lg md:text-2xl text-linear-light -mt-20">
              Linh Han,
              <br />
              Founder and CEO - Hectagon Finance
            </h4>
          </div>

          <div className="translate-y-1/2 text-black bod -mt-52 hidden md:block">
            <h1 className="text-white font-semibold text-2xl md:text-5xl mb-6">
              Team of 35+ passionate builders
            </h1>

            <div className="">
              <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                useKeyboardArrows
                transitionTime={1000}
              >
                <div className="slide-bod flex justify-between pb-4 md:mx-20">
                  {C_LEVEL_1.map((item) => (
                    <CLevelCard item={item} />
                  ))}
                </div>
                <div className="slide-bod flex justify-between md:mx-20 p-4">
                  {C_LEVEL_2.map((item) => (
                    <CLevelCard item={item} />
                  ))}
                </div>
                <div className="slide-bod flex justify-between md:mx-20 mr-4">
                  {C_LEVEL_3.map((item) => (
                    <CLevelCard item={item} />
                  ))}
                </div>
              </Carousel>
            </div>
          </div>
        </div>

        <div className="md:hidden translate-y-1/4 text-black bod -mt-24">
          <h1 className=" w-11/12 m-auto text-white font-semibold text-2xl md:text-5xl mb-6">
            Team of 35+ passionate builders
          </h1>

          <div className="flex overflow-x-auto pb-4">
            {C_LEVEL.map((item) => (
              <CLevelCard item={item} />
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="w-11/12 md:w-10/12 m-auto py-8 md:py-24">
          <div className="hidden flex items-center">
            <div>
              <h1 className="font-semibold text-5xl">
                HECTAGON
                <br />
                <span className="text-linear">Blog</span>
              </h1>

              <a href="" target="_blank">
                <div className="text-black flex items-center font-semibold py-4">
                  View all
                  <img src={arrowBlack} className="w-4 h-4 ml-2.5" alt="" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-44 text-white background_linear" id="roadmap">
        <div className="w-11/12 md:w-10/12 m-auto pt-14 md:pt-24 mb-9 md:mb-20">
          <h2 className="font-semibold text-center text-2xl md:text-5xl md:text-left">
            Road map
          </h2>
          <p className="text-lg text-center md:text-left md:text-2xl font-semibold">
            to a true democratic venture investment
          </p>
        </div>

        <div className="w-11/12 md:w-10/12 m-auto md:hidden pb-16">
          <img src={RoadmapMobile} className="w-full" alt="" />
        </div>

        <div className="relative hidden md:block">
          <div className="flex w-full">
            <div className="h-1 background_linear_process_active absolute top-1/3 translate-y-11 w-7/12"></div>
            <div className="flex justify-end w-full">
              <div className="h-1 background_linear_dot absolute top-1/3 translate-y-11 w-5/12"></div>
            </div>
          </div>
          <div className="flex items-center justify-between w-11/12 md:w-10/12 m-auto pb-24">
            {LIST_ROADMAP.map((item) => (
              <div className="flex flex-col items-center">
                {item.active ? (
                  <>
                    <div className="border-2 p-3 rounded-full">
                      <div className="background_linear_roadmap rounded-full py-6 px-8">
                        <p className="text-2xl text-center font-semibold text-linear-roadmap">
                          {item.month} <br /> {item.year}
                        </p>
                      </div>
                    </div>

                    <div className="border w-7 h-7 background_linear_dot_active rounded-full my-6 flex justify-center items-center z-10">
                      <div className="w-3 h-3 background_linear_dot rounded-full"></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="border-2 p-3 rounded-full">
                      <div className="rounded-full py-6 px-8">
                        <p className="text-2xl text-center font-semibold">
                          {item.month} <br /> {item.year}
                        </p>
                      </div>
                    </div>

                    <div className="border w-7 h-7 background_linear_dot rounded-full my-6 z-10"></div>
                  </>
                )}

                <ul className="list-roadmap">
                  {item.listContent.map((listItem) => (
                    <li className="pb-2">{listItem}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section>
        <div className="relative">
          <img src={RaiseFundImg} className="w-full raise-fund-img" alt="" />
          <div className="text-white absolute top-12 sm:top-1/4 md:top-1/3 w-full">
            <h2 className="md:text-2xl text-center">Are you a founder?</h2>
            <p className="text-3xl md:text-7xl text-center font-bold">
              Raise fund with <span className="block">HECTAGON</span>
            </p>
            <div className="flex justify-center">
              <a href="https://project.hectagon.finance/" target="_blank">
                <button className=" py-3 md:py-4 px-20 order-1 rounded-lg bg-primary text-white text-base font-semibold mt-4">
                  Request funding
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () => (
  <title>Hectagon | The 1st DAO Web3 VC platform for everyone</title>
)
